<template>
  <svg
    viewBox="0 0 1000 1000"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
  >
    <path
      fill="#FFF"
      d="M555.668 258.9754V47.24496791175579l327.3385 327.3241L555.668 701.8941V485.52881146582615c-380.8294-8.9369-364.4728 258.9334-267.5596 434.5814C48.8389 661.5105 99.6385 247.0815 555.668 258.9754z"
    />
  </svg>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

export default defineComponent({
});
</script>

<style lang="scss">
</style>
