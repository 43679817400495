<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 768 768"
  >
    <path d="M640.5 352.5v63h-390l178.5 180-45 45-256.5-256.5 256.5-256.5 45 45-178.5 180h390z" />
  </svg>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

export default defineComponent({
});
</script>

<style lang="scss">
</style>
