<template>
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
  >
    <path
      fill="#FFF"
      d="M744.81 959.5c99.37-180.1 116.14-454.76-274.34-445.6v221.85L134.82 400.12 470.46 64.5v217.1c467.59-12.2 519.68 412.74 274.35 677.9z"
    />
  </svg>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

export default defineComponent({
});
</script>

<style lang="scss">
</style>
